<template>
  <div class="content-wrapper">
    <PageHeader screenName="Ver vacinados" :linkItems="linkItems"/>
    <b-modal
        id="add-new-item"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
        hide-footer
        centered
        v-model="showModal"
    >
      <div v-if="showModal" class="d-flex justify-content-center my-3">
        <output class="spinner-border text-custom-blue" role="status">
          <span class="sr-only">Loading...</span>
        </output>
      </div>
      <h2 class="text-center">Estamos processando</h2>

    </b-modal>
    <div class="card pt-2">
      <main>
        <validation-observer ref="selectRules">
          <b-row class="px-2 pt-2">
            <b-col sm="12" md="12" lg="4">
                  <b-form-group
                      label="Campanha*"
                      label-for="campanha-input"
                      label-class="font_size_label"
                  >
                  <validation-provider
                            #default="{ errors }"
                            name="campanha"
                            rules="required"
                        >
                    <v-select
                        id="select-campanha"
                        variant="custom"
                        label="descricao"
                        placeholder="Selecione uma campanha"
                        :clearable="false"
                        v-model="search.campanha"
                        :values="campanhaSelect"
                        :options="campanhaSelect"
                        :loading="loading.campaign"
                        @input="{
                          cleanSearch(),
                          loadInfo()
                        }"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                    <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                    </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
            <b-col lg="4" md="12" sm="12">
              <b-form-group
                  label="Grupo"
                  label-for="select-grupo"
                  label-class="font_size_label"
              >
                <v-select
                    id="select-grupo"
                    v-model="search.grupo"
                    variant="custom"
                    item-text="descricao_grupo"
                    item-value="id_grupo"
                    label="descricao_grupo"
                    placeholder="Selecione um grupo"
                    :disabled="disableAll || !search.campanha.national"
                    :options="grupoSelect"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
              </b-form-group>
            </b-col>
           
          <b-col lg="4" md="12" sm="12">
            <b-form-group
                label="Empresa"
                label-for="select-empresa"
                label-class="font_size_label"
            >
              <v-select
                  id="select-empresa"
                  v-model="search.empresa"
                  variant="custom"
                  item-text="descricao"
                  item-value="id_empresa"
                  label="nome_empresa"
                  placeholder="Selecione uma empresa"
                  :disabled="disableAll"
                  :values="empresaSelect"
                  :options="empresaSelect"
                  :loading="loading.company"
                  @input="search.endereco = null"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
                <template #option="{nome_empresa, documento}">
                  <strong>{{ nome_empresa }}</strong><br>
                  <span v-if="documento.length == 14">{{documento | VMask('##.###.###/####-##')}}</span>
                  <span v-else>{{documento | VMask('###.###.###-##')}}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          </b-row>
          <b-row class="px-2 pt-lg-1">
          <b-col lg="4" md="12" sm="12" class="pt-lg-3">
            <b-form-group
                label="Endereço"
                label-for="select-empresa"
                label-class="font_size_label"
            >
              <v-select
                  id="select-empresa"
                  v-model="search.endereco"
                  variant="custom"
                  label="endereco"
                  item-value="id_empresa_endereco"
                  placeholder="Selecione um endereço"
                  :disabled="disableAll || !search.campanha.national || !search.empresa"
                  :options="adressOption"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
                <template #option="{descricao,endereco}">
                  <strong v-if="descricao">{{ descricao }}<br></strong>
                  {{ endereco }}
                </template>
                <template slot="selected-option" slot-scope="option">
                    <span>{{ option.endereco }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
            <b-col sm="12" md="12" lg="8">
                  <b-badge  class="w-100 badge-light-blue">Período de vacinação</b-badge>
                  <b-row class="pt-1">
                    <b-col sm="6" md="6" lg="6">
                      <b-form-group
                          label="Data Início"
                          label-for="inicio-input"
                          label-class="font_size_label"
                      >
                      <validation-provider
                            name="Data Início"
                        >
                        <b-input-group>
                          <b-form-input
                              class="form-control"
                              id="inicio-input"
                              v-model="input.inicio"
                              v-mask="'##/##/####'"
                              placeholder="00/00/0000"
                              autocomplete="off"
                              :disabled="disableAll"
                              @input="setInicioInput"
                          />
                          <b-input-group-append>
                            <b-form-datepicker
                                class="form-control d-flex align-items-center"
                                locale="pt-BR"
                                v-model="search.inicio"
                                button-only
                                right
                                :hide-header="true"
                                label-help="Selecione a data de inicio"
                                button-variant="custom-blue"
                                clearable
                                :disabled="disableAll"
                                @input="setInicioPicker"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col sm="6" md="6" lg="6">
                      <b-form-group
                          label="Data Final"
                          label-for="final-input"
                          label-class="font_size_label"
                      >
                      <validation-provider
                            name="Data Final"
                        >
                        <b-input-group>
                          <b-form-input
                              class="form-control"
                              id="final-input"
                              v-model="input.final"
                              v-mask="'##/##/####'"
                              placeholder="00/00/0000"
                              autocomplete="off"
                              :disabled="disableAll"
                              @input="setFinalInput"
                          />
                          <b-input-group-append>
                            <b-form-datepicker
                                class="form-control d-flex align-items-center"
                                locale="pt-BR"
                                v-model="search.final"
                                button-only
                                right
                                :hide-header="true"
                                label-help="Selecione a data final"
                                button-variant="custom-blue"
                                clearable
                                :disabled="disableAll"
                                @input="setFinalPicker"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
          </b-row>
          <b-row class="px-2 pt-lg-1">
            <b-col lg="3" md="6" sm="12">
              <b-form-group
                  label="UF"
                  label-for="select-operador"
                  label-class="font_size_label"
              >
                <v-select
                    id="select-operador"
                    v-model="search.estado"
                    variant="custom"
                    item-text="uf"
                    item-value="uf"
                    label="uf"
                    placeholder="Selecione uma UF"
                    :options="ufSelect"
                    :disabled="disableAll || !search.campanha.national"
                    @input="loadCompanyInfo"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
                {{ search.uf }}
              </b-form-group>
            </b-col>
            <b-col lg="3" md="6" sm="12">
              <b-form-group
                  label="DR SESI"
                  label-for="select-operador"
                  label-class="font_size_label"
              >
                <v-select
                    id="select-operador"
                    v-model="search.operador"
                    variant="custom"
                    item-text="uf_operador"
                    item-value="id_operador"
                    label="uf_operador"
                    placeholder="Selecione um DR"
                    :options="operadorSelect"
                    :disabled="disableAll || !search.campanha.national"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="3">
                  <b-form-group
                      label="Unidade Operacional"
                      label-for="unidade-operacional-input"
                      label-class="font_size_label"
                  >
                    <v-select
                        id="unidade-operacional-input"
                        v-model="search.unidade"
                        :options="unidadeSelect"
                        label="descricao_unidade"
                        placeholder="Selecione uma unidade operacional"
                        :clearable="true"
                        :loading="loading.unity"
                        :disabled="disableAll"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6" lg="3">
                  <b-form-group
                      label="Vacina"
                      label-for="vacina-input"
                      label-class="font_size_label"
                  >
                    <v-select
                        id="vacina-input"
                        v-model="search.vacina"
                        :options="vacinaSelect"
                        label="nome_tecnico_vacina"
                        placeholder="Selecione uma vacina"
                        :clearable="true"
                        :loading="loading.vacina"
                        :disabled="disableAll"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
                </b-row>
                <b-row class="px-2 pt-lg-1">
                <b-col sm="12" md="6" lg="4">
                  <b-form-group
                      label="Lote"
                      label-for="lote-input"
                      label-class="font_size_label"
                  >
                    <v-select
                        id="lote-input"
                        v-model="search.lote"
                        :options="loteSelect"
                        label="numero_lote"
                        placeholder="Selecione o lote"
                        :clearable="true"
                        :loading="loading.lote"
                        :disabled="disableAll"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6" lg="4">
                  <b-form-group
                      label="Vacinador"
                      label-for="vacinador-input"
                      label-class="font_size_label"
                  >
                    <v-select
                        id="select-vacinador"
                        variant="custom"
                        placeholder="Selecione um vacinador"
                        v-model="search.vacinador"
                        :values="vacinadorSelect"
                        :options="vacinadorSelect"
                        :disabled="disableAll"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="12" lg="4">
                  <b-form-group
                      label="Categoria"
                      label-for="perfil-input"
                      label-class="font_size_label"
                  >
                    <v-select
                        id="select-perfil"
                        variant="custom"
                        placeholder="Selecione uma categoria"
                        v-model="search.perfil"
                        :values="perfilSelect"
                        :options="perfilSelect"
                        :disabled="disableAll"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
          </b-row>
              <b-row class="px-2 pt-lg-1">
                <b-col sm="12" md="6" lg="3">
                  <b-form-group
                      label="Nome"
                      label-for="nome-pessoa-input"
                      label-class="font_size_label"
                  >
                    <b-form-input
                        id="nome-pessoa-input"
                        placeholder="Nome da pessoa"
                        v-mask="('X'.repeat(255))"
                        v-model="search.nome"
                        :disabled="disableAll"
                    />
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="6" lg="3">
                  <b-form-group
                      label="CPF"
                      label-for="cpf-pessoa-input"
                      label-class="font_size_label"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="cpf"
                        rules="min:14"
                    >
                      <b-form-input
                          id="cpf-pessoa-input"
                          placeholder="000.000.000-00"
                          v-model="search.cpf"
                          v-mask="'###.###.###-##'"
                          maxLength="14"
                          :disabled="disableAll"
                      />

                      <small v-if="errors[0]" class="text-danger">
                        Este campo deve conter o CPF completo
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6" lg="3">
                  <b-form-group
                      label="Matrícula"
                      label-for="matricula-input"
                      label-class="font_size_label"
                  >
                    <validation-provider
                        name="matricula"
                    >
                      <b-form-input
                          id="matricula-input"
                          placeholder="Identificação interna"
                          v-model="search.matricula"
                          :disabled="disableAll"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6" lg="3">
                  <b-form-group
                      label="Setor"
                      label-for="setor-input"
                      label-class="font_size_label"
                  >
                    <validation-provider
                        name="setor"
                    >
                      <b-form-input
                          id="setor-input"
                          placeholder="Área, setor ou departamento"
                          v-model="search.setor"
                          :disabled="disableAll"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                
               
              </b-row>
          <b-row class="justify-content-center py-3">
            <b-button @click="validateAndFilterList" variant="primary-button" class="mr-2">
              <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
              />
              <span class="align-middle">Pesquisar</span>
            </b-button>

            <b-button type="reset" class="cor_botao" @click="cleanSearch" variant="outline-primary">
              <feather-icon
                  icon="XIcon"
                  class="mr-50"
              />
              <span class="align-middle">Limpar</span>
            </b-button>
          </b-row>
        </validation-observer>
        <hr class="mx-1 mb-1">
        <b-row class="my-2 px-2">
          <b-col lg="6" md="6" sm=6>
            <div class="d-flex align-items-center">
              <span class="mr-1" for="tableSize">Mostrar</span>
              <v-select
                  id="tableSize"
                  v-model="paginationData.defaultSize"
                  @input="filterList()"
                  :options="table.optionsSize"
                  :clearable="false"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
            </div>
          </b-col>
          <b-col lg="6" md="6" sm="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="outline-primary"
                  class="cor_botao"
                  :disabled="activeButtonSheet || disableAll || loadingData"
                  @click="downloadSpreadsheet()"
                  v-b-tooltip.hover.bottom="'Exportação de planilha de vacinados'"
              >
                <feather-icon
                    id="download-import"
                    role="button"
                    class="text-custom-blue"
                    icon="DownloadIcon"
                    size="18"
                />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </main>

      <section class="list-vacinacao" v-if="!loadingData">
        <b-table
            id="tableVacinacao"
            ref="tableVacinacao"
            responsive
            :stickyColumn="true"
            :busy.sync="filtering"
            :fields="table.fields"
            :items="table.items"
            @sort-changed="ordenarColuna"
            no-local-sorting
            :no-sort-reset="true"
        >
          <template #head(data_aplicacao)>
            <span class="d-block">APLICAÇÃO</span>
            <span class="d-block">VACINADOR</span>
          </template>
          <template #head(setor)>
            <span class="d-block">SETOR</span>
            <span class="d-block">MATRÍCULA</span>
          </template>

          <template #cell(nome)="row">
            <strong class="d-block comprimento_maximo_celulas">{{ row.item.nome }}</strong>
            <small class="d-block">{{ row.item.cpf | VMask('###.###.###-##') }}</small>
          </template>

          <template #cell(data_aplicacao)="row">
            <span class="d-block">{{ moment(row.item.data_aplicacao).format('DD/MM/YYYY') }}</span>
            <span class="d-block">{{ row.item.usuario_aplicador }}</span>
          </template>
          <template #cell(setor)="row">
            <span class="d-block">{{ row.item.setor }}</span>
            <span class="d-block">{{ row.item.matricula }}</span>
            <span class="d-block"
                  v-if="!row.item.matricula && !row.item.setor"
            >-</span>
          </template>
          <template #cell(numero_lote)="row">
            <span class="d-block">{{ row.item.numero_lote }}</span>
            <span class="d-block">{{ row.item.fabricante }}</span>
          </template>
          <template #cell(validade_lote)="row">
            <span class="d-block">{{ moment(row.item.validade_lote).format('DD/MM/YYYY') }}</span>
          </template>
        </b-table>
        <div class="pb-2 px-1">
          <span v-if="without" class="ml-1">Nenhum colaborador encontrado</span>
          <b-col sm="12" class="mt-2">
            <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
          </b-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BModal,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BCard,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BBadge,
  BOverlay
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import moment from 'moment';
import {min} from '@validations';
import vSelect from 'vue-select';
import {
  getNationalPurchaseGroups,
  getDepartmentsOperatorGroups
} from '@/views/custom-pages/adesao-nacional/requests'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import InfograficoEstoqueCard from '@/views/components/custom/cards/InfograficoEstoqueCard.vue';
import InfoCard from '@/views/components/custom/cards/InfoCard.vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import {getUserData} from "@/auth/utils";
import {actions, subjects} from "@/libs/acl/rules";

export default {
  title: 'Ver vacinados',

  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    TableSwitch,
    BCard,
    vSelect,
    CustomPagination,
    PageHeader,
    TimedAlert,
    VuePerfectScrollbar,
    BSpinner,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    InfograficoEstoqueCard,
    InfoCard,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    getNationalPurchaseGroups,
    getDepartmentsOperatorGroups,
  },

  data() {
    return {
      moment,
      min,
      linkItems: [
        {
          name: 'Vacinação',
          active: true
        },
        {
          name: 'Ver vacinados',
          active: true
        }
      ],
      alert: {
        errorMessage: '',
        show: false
      },
      loadingData: false,
      filtering: false,
      table: {
        fields: [
          {key: "nome", label: "NOME | CPF", sortable: true, class: 'align-middle mw-200'},
          {key: "setor", label: "SETOR MATRÍCULA", sortable: false, class: 'align-middle mw-50'},
          {
            key: "vacina",
            label: "VACINA",
            sortable: false,
            class: 'align-middle mw-100'
          },
          {key: "numero_lote", label: "LOTE | FABRICANTE", sortable: false, class: 'align-middle mw-50'},
          {key: "validade_lote", label: "VALIDADE", sortable: false, class: 'align-middle mw-50'},
          {key: "data_aplicacao", label: "APLICAÇÃO VACINADOR", sortable: false, class: 'align-middle mw-50'},
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'nome',
        order: 'asc'
      },
      paginationData: {
        currentPage: 1,
        defaultSize: 10,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false
      },
      isAdvancedSearchActive: false,
      search: {
        unidade: '',
        empresa: '',
        campanha: '',
        vacinador: '',
        perfil: '',
        nome: '',
        cpf: '',
        inicio: '',
        final: '',
        matricula: '',
        setor: '',
        grupo: '',
        operador: '',
        lote: '',
        vacina: '',
        endereco: '',
        estado: '',
      },
      input: {
        inicio: null,
        final: null,
      },
      loading: {
        company: false,
        campaign: true,
        unity: false,
        lote: false,
        vacina: false
      },
      unidadeSelect: [],
      empresaSelect: [],
      campanhaSelect: [],
      vacinadorSelect: [],
      loteSelect: [],
      vacinaSelect: [],
      perfilSelect: [],
      grupoSelect: [],
      operadorSelect: [],
      adressSelect: [],
      ufSelect: [],
      parameters: {
        colunaOrdenar: 'pessoa_fisica.nome',
        ordem: 'asc',
        data_inicial: this.getDateRangeInitialValuesWithFormat("YYYY-MM-DD").startDate,
        data_final: this.getDateRangeInitialValuesWithFormat("YYYY-MM-DD").endDate,
      },
      disableAll: true,
      activeButtonSheet: false,
      showModal: false,
      userData: getUserData(),
      companyUser: this.$can(actions.VISUALIZAR, subjects.EMPRESAS_AE)
    }
  },
  mounted() {
    this.loadingData = true;
    this.loadCampaign();
  },
  methods: {
    loadGroup(){
      const params = {
        id_campanha: this.search.campanha.id_campanha,
      }
      this.$http.get(this.$api.grupoOperadorUf(), {params}).then(({ data }) => {
          this.grupoSelect = data.grupos;
          this.ufSelect = data.estados;
          this.operadorSelect = data.operadores;
        }).catch(() => {
        this.$helpers.modalErroServidorPadrao();
        });   
    },
    loadCampaign() {
      const parameters = {
        ativo: true,
        paginacao: false,
        colunaNome: 'termino_campanha',
        situacao: ['Em andamento', 'Concluída']
      }
      this.$http.get(this.$api.campanha(), {params: parameters}).then(({data}) => {
        this.campanhaSelect = data.map(option => ({
          descricao: option.campanha_descricao,
          id_campanha: option.id_campanha,
          startCampaign: option.inicio_campanha,
          endCampaign: option.termino_campanha,
          national: option.tipo == 'Nacional'
        }));
        this.loading.campaign = false;
      });
    },
    async loadInfo() {
      this.disableAll = false;
      this.loading.company = true;
      this.loading.lote = true;
      this.loading.unity = true;
      this.loading.vacina = true;

      const startDate = moment(this.search.campanha.startCampaign).format('YYYY-MM-DD');
      const endDate = moment(this.search.campanha.endCampaign).format('YYYY-MM-DD');
      
      this.setInicioPicker(startDate);
      this.setFinalPicker(endDate);

      const parameters = {
        ativo: true,
        paginacao: false,
      }

      const parametersLote = {dependencia: false, ativo: false, colunaOrdenar: "numero_lote", ordem: "asc"};

      await this.$http.get(this.$api.unidade(), {params: parameters}).then(({data}) => {
        this.unidadeSelect = data;
        this.loading.unity = false;
      });
      
      await this.$http.get(this.$api.vacinadores()).then(({data}) => {
        this.vacinadorSelect = data.map(option => ({
              value: option.id_usuario,
              label: option.nome
            }
        ));
      });
      await this.$http.get(this.$api.getEmpresaCategoria()).then(({data}) => {
        this.perfilSelect = data.map(option => ({
          value: option.id_categoria,
          label: option.descricao,
        }));
      });
      await this.$http.get(this.$api.lote(), {params: parametersLote}).then(({data}) => {

        const lotes = new Set();
        
        this.loteSelect = data.filter((lote) => {
          const duplicatedLote = lotes.has(lote.id_lote);
          lotes.add(lote.id_lote);
          return !duplicatedLote;
        })

        this.loading.lote = false;
      });
      await this.$http.get(this.$api.saveAndGetVacina(), {params: parameters}).then(({data}) => {
        this.vacinaSelect = data.data;
        this.loading.vacina = false;
      });

      await this.loadCompanyInfo();
      await this.loadGroup();
    },
    loadCompanyInfo() {
      const parametersEmpresa = {
        id_campanha: this.search.campanha.id_campanha,
        uf_empresa: this.search.estado ? this.search.estado.uf : null,
      }

      this.$http.get(this.$api.empresaCampanha(), {params: parametersEmpresa}).then(({data}) => {
        this.empresaSelect = data;
        this.loading.company = false;
      });
    },
    async loadData() {
      await this.$http.get(this.$api.vacinacao(), {
        params: {
          pagina: this.paginationData.currentPage,
          tamanho: this.paginationData.defaultSize,
          ...this.parameters
        }
      }).then(({data}) => {
        this.table.items = data.data;
        this.startPagination(data);
      });
      this.filtering = false;
      this.loadingData = false;
    },
    startPagination(data) {
      this.paginationData.currentPage = data.current_page;
      this.paginationData.totalLines = data.total;
      this.paginationData.fromLine = data.from;
      this.paginationData.toLine = data.to;
    },
    updateCurrentPage(page) {
      this.paginationData.currentPage = page;
      this.filtering = true;
      this.loadData();
    },
    async filterList() {
      const parameters = {
        id_unidade: this.search.unidade ? this.search.unidade.id_unidade : '',
        id_empresa: this.search.empresa ? this.search.empresa.id_empresa : '',
        id_campanha: this.search.campanha ? this.search.campanha.id_campanha : '',
        id_usuario_vacinador: this.search.vacinador ? this.search.vacinador.value : '',
        id_lote: this.search.lote ? this.search.lote.id_lote : '',
        id_vacina: this.search.vacina ? this.search.vacina.id_vacina : '',
        id_perfil: this.search.perfil ? this.search.perfil.value : '',
        data_final: this.search.final,
        data_inicial: this.search.inicio,
        cpf: this.formatCpf(this.search.cpf),
        termoPesquisa: this.search.nome,
        colunaOrdenar: this.table.columnOrder,
        matricula: this.search.matricula,
        setor: this.search.setor,
        ordem: this.table.order,
        id_grupo: this.search.grupo ? this.search.grupo.id_grupo : '',
        id_departamento_operador: this.search.operador ? this.search.operador.id_operador : '',
        uf_empresa:  this.search.operador ? this.search.estado.uf : '',
        id_empresa_endereco: this.search.endereco ? this.search.endereco.id_empresa_endereco : ''
      }
      this.parameters = parameters;
      this.filtering = true;
      this.paginationData.currentPage = 1;
      this.loadData();
    },
    cleanSearch() {
      this.search.unidade = ''
      this.search.empresa = ''
      this.search.vacinador = '';
      this.search.perfil = '';
      this.search.nome = '';
      this.search.cpf = '';
      this.search.inicio = '';
      this.search.final = '';
      this.search.matricula = '';
      this.search.setor = '';
      this.search.lote = '';
      this.search.vacina = '';
      this.input.inicio = '';
      this.input.final = '';
      this.search.grupo = '';
      this.search.operador = '';
      this.search.endereco = '';
      this.search.estado = '';
      this.parameters = {
        colunaOrdenar: this.table.columnOrder,
        ordem: this.table.order,
        data_inicial: "",
        data_final: ""
      };
    },
    ordenarColuna(sort) {
      this.table.columnOrder = sort.sortBy;
      this.table.order = sort.sortDesc ? 'desc' : 'asc';

      this.filtering = true;

      this.filterList();
    },
    toggleAdvancedSearch() {
      this.isAdvancedSearchActive = !this.isAdvancedSearchActive;
    },
    setInicioInput(value) {
      this.input.inicio = value;
      this.search.inicio = value ? moment(this.input.inicio, 'DD/MM/YYYY').format('YYYY-MM-DD') : value;
    },
    setInicioPicker(value) {
      this.search.inicio = value;
      this.input.inicio = value ? moment(this.search.inicio).format('DD/MM/YYYY') : value;
    },
    setFinalInput(value) {
      this.input.final = value;
      this.search.final = value ? moment(this.input.final, 'DD/MM/YYYY').format('YYYY-MM-DD') : value;
    },
    setFinalPicker(value) {
      this.search.final = value;
      this.input.final = value ? moment(this.search.final).format('DD/MM/YYYY') : value;
    },
    validateAndFilterList(){
      this.$refs.selectRules.validate().then(success =>{
        if (success){
          this.filterList();
        }
      })
    },
    formatCpf(cpf) {
      return cpf.replace(/\D/g, "")
    },
    async downloadSpreadsheet() {
      this.activeButtonSheet = true
      this.showModal = true
      await this.$http.get(this.$api.vacinacao(), {
        params: {
          id_campanha: this.search.campanha.id_campanha,
          ...this.parameters
        }
      }).then(({data}) => {

        const items = data.map(item => ({
          nome: item.nome,
          cpf: item.cpf,
          setor: item.setor ? item.setor : '-',
          matricula: item.matricula ? item.matricula : '-',
          vacina: item.vacina,
          lote: item.numero_lote,
          fabricante: item.fabricante,
          validade: moment(item.validade_lote).format('DD/MM/YYYY'),
          data_aplicacao: moment.utc(item.data_aplicacao).format('DD/MM/YYYY'),
          aplicador: item.usuario_aplicador,
          grupo: item.grupo,
          uf: item.uf,
          "cnpj/cpf": item.cnpj,
          nome_fantasia: item.nome_fantasia,
          razao_social: item.razao_social,
          campanha: this.search.campanha.descricao
        }));
        this.$helpers.downloadArchiveXlsx(items, 'planilha_vacinacao.xlsx')

      }).finally(() => {
        this.showModal = false
        this.activeButtonSheet = false
      });
    },
    formatDocument(document) {
      if (document.length === 14) {
        return this.$options.filters.VMask(document, '##.###.###/####-##');
      } else if (document.length === 11) {
        return this.$options.filters.VMask(document, '###.###.###-##');
      } else {
        return document;
      }
    },

    getDateRangeInitialValuesWithFormat(format = 'YYYY-MM-DD') {
      const endDate = moment(new Date()).format(format);
      const startDate = moment().startOf('year').format(format);

      return {startDate, endDate};
    }
  },
  computed: {
    without() {
      return this.table.items.length == 0 ? true : false;
    },
    adressOption(){
      let adresses = []

      if(this.search.empresa){
        adresses = this.companyUser ? this.search.empresa.enderecos : this.search.empresa.enderecos_adesao
      }

      return adresses;
    }
  }
}
</script>

<style>
.font_size_label {
  font-size: 15px;
  margin-top: -5px;
}

.cor_botao {
  color: #2772C0 !important;
  border: 1px solid #2772C0 !important;
}

.comprimento_maximo_celulas {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}

.badge-light-blue {
  background: rgba(39, 114, 192, 0.12);
  color: #5E5873;
  font-size: 1rem;
  font-weight: 400;
  padding: .5rem 0;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  max-height: 0;
}

.fade-leave-active {
  animation: fade .2s ease-in-out reverse;
}

.fade-enter-active {
  animation: fade .2s ease-in-out forwards;
}

@keyframes fade {
  from {
    opacity: 0;
    max-height: 0;
  }

  to {
    opacity: 1;
    max-height: 50vh;
  }
}

#tableSize {
  min-width: 80px;
}
</style>
